/*
 * @Author: your name
 * @Date: 2022-02-11 11:13:55
 * @LastEditTime: 2022-03-25 10:51:37
 * @LastEditors: 杨月星
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\element-install\index.ts
 */
/* 全局注册vant组件 */
import { VueConstructor } from 'vue'
import {
  Button,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Card,
  Form,
  FormItem,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Select,
  Option,
  Tag,
  Image,
  Pagination,
  Loading,
  Dialog,
  Message,
  Row,
  Col,
  Descriptions,
  DescriptionsItem,
  Collapse,
  CollapseItem,
  Empty,
  Tooltip,
  MessageBox,
  Upload,
  DatePicker
} from 'element-ui'

const components = [
  Button,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Card,
  Form,
  FormItem,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Select,
  Option,
  Tag,
  Pagination,
  Loading,
  Dialog,
  Row,
  Col,
  Image,
  Descriptions,
  DescriptionsItem,
  Collapse,
  CollapseItem,
  Empty,
  Tooltip,
  Upload,
  DatePicker
]

const install = (Vue: VueConstructor): void => {
  components.forEach((item) => {
    Vue.use(item)
  })
  Vue.prototype.$loading = Loading.service
  Vue.prototype.$message = Message
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
}
export default { install }
