








import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ColumnConfig } from './types'

@Component
export default class extends Vue {
  @Prop() tableData!: any;
  @Prop() column!: Array<ColumnConfig>;
  @PropSync('visible') newVisible!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop() title?: string;
  @Prop({ type: Number, default: 0 }) total!: number;
  @Prop({ type: Number, default: 10 }) pageSize!: number;

  close () {
  // this.$emit('update:tableData', [])
    this.newVisible = false
  }

  PageSizeChange (size:number) {
    this.$emit('changePagination', size)
  }

  /**
   * @description 渲染表格
   */
  render () {
    return (
      <div>
        <el-dialog title={this.title} visible={this.newVisible} onclose={() => this.close()}>
          {
            <data-table loading={this.loading} tableData={this.tableData} column={this.column} total={this.total} pageSize={this.pageSize} onChangePagination={this.PageSizeChange}></data-table>
          }
          <div slot="footer" class="foot-container"></div>
        </el-dialog>
      </div>
    )
  }
}
