/*
 * @Author: your name
 * @Date: 2022-02-11 11:13:31
 * @LastEditTime: 2022-03-25 10:51:54
 * @LastEditors: 杨月星
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\request\api.ts
 */
/* TODO:此处思考采用其他方式 */
const api = {
  /**  路由：登录接口 */
  login: '/api/v1/authenticate',
  /**  路由：社区管理列表 */
  communityListPage: '/api/v1/community/listPage',
  /**  路由：社区管理-未开通列表 */
  notOpencommunity: '/api/v1/community/listPage4NotOpen',
  /**  路由：社区管理操作 */
  updateCommunity: '/api/v1/community/updateCommunity',
  /**  路由：社区创建 */
  createCommunity: '/api/v1/community/create',
  /** 路由：社区下拉接口 */
  getCommunityList: '/api/v1/community/getCommunityList',
  /**  路由：获取所有省市数据 */
  pccByParent: '/api/v1/locations/pccByParent',
  /**  路由：获取所有区数据 */
  countybyParents: '/api/v1/locations/countybyParents',

  /** 路由：用户管理接口分页 */
  userListPage: '/api/v1/user/listPage',
  /** 路由：用户管理-用户操作-重置签到 */
  resetSign: '/api/v1/user/resetActivity',
  /** 路由：用户管理-用户操作-再次认证 */
  resetAuthStatus: '/api/v1/user/resetAuthStatus',
  /** 路由：用户管理-用户活跃度展示 */
  activeDetail: '/api/v1/user/activeDetail',
  /** 路由：用户管理-用户参与活动详情 */
  activityDetail: '/api/v1/user/activityDetail',
  /** 路由：用户管理-TA邀请的人 */
  inviteDetail: '/api/v1/user/inviteDetail',
  /** 路由：用户管理-提现详情 */
  withdrawalDetail: '/api/v1/user/withdrawalDetail',
  /** 路由：用户管理-发布详情列表 */
  publishList: '/api/v1/article/listPage4Web',
  /** 路由：用户管理操作 */
  updateUser: '/api/v1/user/updateUser',
  /** 路由：用户数量统计 */
  getUserTotal: '/api/v1/user/getTotal',
  /** 路由：用户详情统计接口 */
  userMoreTotal: '/api/v1/user/detail',
  /** 路由：用户详情分页接口 */
  listPage4Web: '/api/v1/article/listPage4Web',
  /** 路由：用户详情查看评论接口 */
  commentsListPage4Web: '/api/v1/article/commentsListPage4Web',
  /** 路由：删除帖子接口 */
  deleteArticle: '/api/v1/article/delete',
  /** 路由：举报投诉分页接口 */
  complaintListPage: '/api/v1/complaint/listPage',
  /** 路由：举报详情接口 */
  complaintDetail: '/api/v1/complaint/detail',
  /** 路由：举报相关操作：通过，驳回 */
  updateComplaint: '/api/v1/complaint/updateComplaint',
  /** 路由：提现列表查询 */
  issueListPage: '/api/v1/issue/listPage',
  /** 路由：提现列表-活动查询 */
  issueActivity: '/api/v1/issue/activity/',
  /** 路由：提现列表发放优惠券和现金统计 */
  issueGetTotal: '/api/v1/issue/getTotal',
  /** 路由：提现列表发放奖励 */
  doIssue: '/api/v1/issue/doIssue',
  /** 路由：提现列表-活动查询 */
  activityList: '/api/v1/activity/list',
  /** 路由：提现列表导出 */
  issueExport: '/api/v1/issue/export'

}
export default api
