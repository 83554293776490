/*
 * @Author: your name
 * @Date: 2022-03-04 10:42:53
 * @LastEditTime: 2022-03-04 10:52:35
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\components\dialog-table\index.ts
 */
import { VueConstructor } from 'vue'
import DialogTable from './index.vue'

export default {
  install: (Vue:VueConstructor) => {
    /* TODO:这里组件的名字思考采用其他形式改写 */
    Vue.component('DialogTable', DialogTable)
  }
}
