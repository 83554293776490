/*
 * @Author: your name
 * @Date: 2022-02-11 11:13:31
 * @LastEditTime: 2022-03-17 10:12:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\main.ts
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import element from '@/element-install'
import { Get, Post, Delete, reportFileDownload } from '@/request/index'
import Api from '@/request/api'
import table from '@/components/table/index'
import dialogTable from '@/components/dialog-table/index'

Vue.config.productionTip = false

Vue.prototype.$Get = Get
Vue.prototype.$Post = Post
Vue.prototype.$Delete = Delete
Vue.prototype.$Download = reportFileDownload
Vue.prototype.$Api = Api

Vue.use(element)
Vue.use(table)
Vue.use(dialogTable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 定义ts避免报错
declare module 'vue/types/vue' {
    interface Vue {
        $Get: typeof Get
        $Post: typeof Post
        $Delete:typeof Delete
        $Api: typeof Api
    }
}
